import { m, LazyMotion, domAnimation } from 'framer-motion'
import IconButton from '@mui/material/IconButton'
import { memo } from 'react'

const MenuToggle = ({ toggle }: { toggle: () => void }) => {
  return (
    <IconButton
      disableRipple
      size="large"
      edge="start"
      aria-label="open drawer"
      onClick={toggle}
      sx={{
        '& svg': {
          fontSize: 24,
          userSelect: 'none',
          width: '1em',
          height: '1em',
          flexShrink: 0,
          verticalAlign: 'middle',
        },
      }}
    >
      <LazyMotion features={domAnimation}>
        <m.svg width="16" height="16" viewBox="0 0 1024 1024">
          <m.path
            fill="#000"
            d="M170.666667 170.666667h682.666666a42.666667 42.666667 0 0 1 0 85.333333H170.666667a42.666667 42.666667 0 1 1 0-85.333333z m0 298.666666h682.666666a42.666667 42.666667 0 0 1 0 85.333334H170.666667a42.666667 42.666667 0 0 1 0-85.333334z m0 298.666667h682.666666a42.666667 42.666667 0 0 1 0 85.333333H170.666667a42.666667 42.666667 0 0 1 0-85.333333z"
          />
        </m.svg>
      </LazyMotion>
    </IconButton>
  )
}

export default memo(MenuToggle)
